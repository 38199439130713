var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper-card-history-transaction" }, [
    _vm.isLoading
      ? _c("div", { staticClass: "loading" }, [_c("t-placeholder")], 1)
      : _c(
          "div",
          _vm._l(_vm.transactionHistory, function(data, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "tui tuim ui card item history transaction"
              },
              [
                _c("div", { staticClass: "content spacer" }, [
                  _c("span", {
                    staticClass: "status-icon",
                    domProps: {
                      innerHTML: _vm._s(_vm.returnStatusIcon(data.status))
                    }
                  }),
                  _c("span", {
                    staticClass: "status-code",
                    domProps: { textContent: _vm._s(data.normalizedReturnCode) }
                  }),
                  _c("div", { staticClass: "extra content" }, [
                    _c("span", {
                      staticClass: "created",
                      domProps: {
                        textContent: _vm._s(_vm.formatDate(data.createdAt))
                      }
                    }),
                    _c("h3", {
                      staticClass: "transaction-status",
                      domProps: { textContent: _vm._s(data.statusLabel) }
                    }),
                    _c("p", {
                      domProps: { textContent: _vm._s(data.description) }
                    })
                  ])
                ])
              ]
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }