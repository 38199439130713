<template>
  <div class="wrapper-card-history-transaction">
    <div v-if="isLoading" class="loading">
      <t-placeholder />
    </div>
    <div v-else>
      <div class="tui tuim ui card item history transaction"
        v-for="(data, i) in transactionHistory" :key="i">
        <div class="content spacer">
          <span class="status-icon" v-html="returnStatusIcon(data.status)" />
          <span class="status-code" v-text="data.normalizedReturnCode" />
          <div class="extra content">
            <span class="created" v-text="formatDate(data.createdAt)"/>
            <h3 class="transaction-status" v-text="data.statusLabel"/>
            <p v-text="data.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { dateTimeFormatDate, getStatusIcon } from '@/helpers'

export default {
  name: 'TGroupHistoryTransaction',

  computed: {
    ...mapState({
      transactionHistory: ({ transactions }) => transactions.transactionHistory,
      isLoading: ({ transactions }) => transactions.showDetailForm
    })
  },

  methods: {

    returnStatusIcon: (status) => getStatusIcon(status),

    formatDate: (date) => `${dateTimeFormatDate(date)}h`

  }
}
</script>

<style lang="scss">

.tui.tuim.page-sidebar > .inner {
  padding: 40px 0px 40px 0;
}

.tui.tuim.ui.segments.history.transaction {
  margin-top: 43px;

  .wrapper-card-history-transaction {

    .loading { padding: 20px }

    .card.item.history.transaction {
      border: none;
      padding-bottom: 30px;
      position: relative;

      &::before {
        content: '';
        width: 2px;
        height: 90px;
        background-color: #e2e2e3;
        position: absolute;
        bottom: -14px;
        left: 32px;
        z-index: 1;
      }

      &:last-of-type {
        padding-bottom: 0;

        &::before {
          display: none;
        }
      }
    }

    .card.item.history .content.extra {
      padding-left: 15px;
      max-width: 180px;

      .created { text-transform: none; }
      .transaction-status { text-transform: capitalize; }
    }
  }
}

.status-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  margin-top: 5px;

  .icon { font-size: 30px }
}

.status-code {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0px 10px 5px;
  color: #7a7a7a;
  max-width: 30px;
}
</style>
